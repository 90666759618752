import { gql } from '@apollo/client';

export const GET_THEMES = gql`
    query getThemesAndFonts {
        themeListList {
            id
            themeId
            colorPrimary
            colorSecondary
            textPrimary
            textSecondary
            error
            warning
            info
            success
            background
            panelBackground
            h1
            h2
            h3
            h4
            h5
            h6
            body1
            body2
            button
            h1Font
            h2Font
            h3Font
            h4Font
            h5Font
            h6Font
            body1Font
            body2Font
            buttonFont
            xsBreakpoint
            smBreakpoint
            mdBreakpoint
            lgBreakpoint
            xlBreakpoint
            defaultTheme
            themeName
            fontFamily
        }
        getFonts {
            family
        }
        settingGroups(options: { code: "GEN" }) {
            settings {
                id
                settingGroupId
                attributeId
                displayName
                name
                enumValueId
                value
                textValue
                enumId
                isEnum
                attributeEntityValueId

                __typename
            }
            __typename
        }
    }
`;

export const CREATE_THEME = gql`
    mutation CreateNewTheme($themeInput: ThemeInput!) {
        createNewTheme(themeInput: $themeInput) {
            id
            themeId
            colorPrimary
            colorSecondary
            textPrimary
            textSecondary
            error
            warning
            info
            success
            background
            panelBackground
            h1
            h2
            h3
            h4
            h5
            h6
            body1
            body2
            h1Font
            h2Font
            h3Font
            h4Font
            h5Font
            h6Font
            body1Font
            body2Font
            button
            buttonFont
            xsBreakpoint
            smBreakpoint
            mdBreakpoint
            lgBreakpoint
            xlBreakpoint
            defaultTheme
            themeName
            fontFamily
        }
    }
`;

export const DELETE_THEME = gql`
    mutation deleteTheme($themeInput: ThemeInput!) {
        deleteTheme(themeInput: $themeInput) {
            Success
        }
    }
`;
export interface ThemeInputBase {
    themeId?: string;
    themeName: string;
}
export interface ThemeInput extends ThemeInputBase {
    textPrimary: string;
    textSecondary: string;
    colorPrimary: string;
    colorSecondary: string;
    success: string;
    error: string;
    info: string;
    warning: string;
    background: string;
    panelBackground: string;
    themeName: string;
    baseTheme: string;
    newOrExisting: string;
    defaultTheme: boolean;
    fontFamily: string;
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    h6: number;
    body1: number;
    body2: number;
    button: number;
    h1Font: string | undefined;
    h2Font: string | undefined;
    h3Font: string | undefined;
    h4Font: string | undefined;
    h5Font: string | undefined;
    h6Font: string | undefined;
    body1Font: string | undefined;
    body2Font: string | undefined;
    buttonFont: string | undefined;
}
export interface Font {
    family: string;
}

export interface ThemeData {
    themeListList: ThemeInput[];
    getFonts: Font[];
}
