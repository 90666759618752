import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOverride } from './interfaces/theme';

declare module '@material-ui/core/styles/createPalette' {
	interface Palette {
		theme: PaletteColor;
	}
	interface PaletteOptions {
		theme: PaletteColorOptions
	}
};

const darkTheme = false;

/*** Breakpoints ***/
const breakpoints = {
	xs: 0,
	sm: 600,
	md: 960,
	lg: 1280,
	xl: 1920,
};

/*** Colors ***/
const colors = {
	theme: {
		main: '#2D4E6E',
		light: '#395D80',
		dark: '#234361',
		contrastText: '#FFFFFF',
	},
	primary: {
		main: '#2196f3',
		light: '#62EFCD',
		dark: '#0069C0',
		contrastText: '#FFFFFF',
	},
	secondary: {
		main: '#18BC9C',
		light: '#6EC6FF',
		dark: '#008B6E',
		contrastText: '#FFFFFF',
	},
	error: {
		main: '#D32F2F',
		light: '#D35B5B',
		dark: '#A30606',
		contrastText: '#FFFFFF',
	},
	success: {
		main: '#13902E',
		light: '#38B553',
		dark: '#09641D',
		contrastText: '#FFFFFF',
	},
	info: {
		main: '#2196F3',
		light: '#64B5F6',
		dark: '#1976D2',
		contrastText: '#FFFFFF',
	},
	warning: {
		main: '#FF9800',
		light: '#FFB74D',
		dark: '#F57C00',
		contrastText: '#FFFFFF',
	},
};

/*** Light ***/
const lightPalette = {
	type: 'light',
	common: {
		black: '#000000',
		white: '#FFFFFF'
	},
	background: {
		paper: '#FFFFFF',
		default: '#EDEDEF',
	},
	divider: 'rgba(0, 0, 0, 0.12)',
	contrastThreshold: 3,
	tonalOffset: 0.2,
	action: {
		active: 'rgba(0, 0, 0, 0.54)',
		hover: 'rgba(0, 0, 0, 0.04)',
		hoverOpacity: 0.04,
		selected: `${colors.primary.main}14`,
		selectedOpacity: 0.08,
		disabled: 'rgba(0, 0, 0, 0.26)',
		disabledBackground: 'rgba(0, 0, 0, 0.12)',
		disabledOpacity: 0.38,
		focus: 'rgba(0, 0, 0, 0.12)',
		focusOpacity: 0.12,
		activatedOpacity: 0.12,
	},
	text: {
		primary: '#272729',
		secondary: 'rgba(0, 0, 0, 0.54)',
		disabled: 'rgba(0, 0, 0, 0.38)',
		hint: 'rgba(0, 0, 0, 0.38)',
		icon: 'rgba(0, 0, 0, 0.38)',
	},
	grey: {
		'50': '#F2F2F2',
		'100': '#E5E5E5',
		'200': '#CCCCCC',
		'300': '#B2B2B2',
		'400': '#999999',
		'500': '#7F7F7F',
		'600': '#666666',
		'700': '#4C4C4C',
		'800': '#333333',
		'900': '#191919',
		'A100': '#A6A6A6',
		'A200': '#8C8C8C',
		'A400': '#262626',
		'A700': '#4C4C4C',
	},
	// grey: {
	// 	'50': 'rgba(0, 0, 0, 0.05)',
	// 	'100': 'rgba(0, 0, 0, 0.1)',
	// 	'200': 'rgba(0, 0, 0, 0.2)',
	// 	'300': 'rgba(0, 0, 0, 0.3)',
	// 	'400': 'rgba(0, 0, 0, 0.4)',
	// 	'500': 'rgba(0, 0, 0, 0.5)',
	// 	'600': 'rgba(0, 0, 0, 0.6)',
	// 	'700': 'rgba(0, 0, 0, 0.7)',
	// 	'800': 'rgba(0, 0, 0, 0.8)',
	// 	'900': 'rgba(0, 0, 0, 0.9)',
	// 	'A100': 'rgba(0, 0, 0, 0.35)',
	// 	'A200': 'rgba(0, 0, 0, 0.45)',
	// 	'A400': 'rgba(0, 0, 0, 0.85)',
	// 	'A700': 'rgba(0, 0, 0, 0.7)',
	// },
	...colors,
};

/*** Dark ***/
const darkPalette = {
	type: 'dark',
	common: {
		black: '#000000',
		white: '#FFFFFF'
	},
	background: {
		// paper: '#262626',
		// default: '#333333',
		paper: '#333333',
		default: '#262626',
	},
	divider: '#FFFFFF1F',
	contrastThreshold: 3,
	tonalOffset: 0.2,
	action: {
		active: '#FFFFFF',
		hover: 'rgba(255, 255, 255, 0.08)',
		hoverOpacity: 0.08,
		selected: 'rgba(255, 255, 255, 0.16)',
		selectedOpacity: 0.16,
		disabled: 'rgba(255, 255, 255, 0.38)',
		disabledBackground: 'rgba(255, 255, 255, 0.12)',
		disabledOpacity: 0.38,
		focus: 'rgba(255, 255, 255, 0.12)',
		focusOpacity: 0.12,
		activatedOpacity: 0.24,
	},
	text: {
		primary: '#E3E3E5',
		secondary: '#848486',
		disabled: 'rgba(255, 255, 255, 0.5)',
		hint: 'rgba(255, 255, 255, 0.5)',
		icon: 'rgba(255, 255, 255, 0.5)',
	},
	grey: {
		'50': '#3D3D3D',
		'100': '#484848',
		'200': '#5C5C5C',
		'300': '#717171',
		'400': '#858585',
		'500': '#999999',
		'600': '#ADADAD',
		'700': '#C2C2C2',
		'800': '#D6D6D6',
		'900': '#EBEBEB',
		'A100': '#7A7A7A',
		'A200': '#8F8F8F',
		'A400': '#E1E1E1',
		'A700': '#C2C2C2',
	},
	// grey: {
	// 	'50': 'rgba(255, 255, 255, 0.05)',
	// 	'100': 'rgba(255, 255, 255, 0.1)',
	// 	'200': 'rgba(255, 255, 255, 0.2)',
	// 	'300': 'rgba(255, 255, 255, 0.3)',
	// 	'400': 'rgba(255, 255, 255, 0.4)',
	// 	'500': 'rgba(255, 255, 255, 0.5)',
	// 	'600': 'rgba(255, 255, 255, 0.6)',
	// 	'700': 'rgba(255, 255, 255, 0.7)',
	// 	'800': 'rgba(255, 255, 255, 0.8)',
	// 	'900': 'rgba(255, 255, 255, 0.9)',
	// 	'A100': 'rgba(255, 255, 255, 0.35)',
	// 	'A200': 'rgba(255, 255, 255, 0.45)',
	// 	'A400': 'rgba(255, 255, 255, 0.85)',
	// 	'A700': 'rgba(255, 255, 255, 0.7)',
	// },
	...colors,
};

/*** Palette ***/
const palette = (darkTheme) ? darkPalette : lightPalette;

/*** Typography ***/
const typography = {
	htmlFontSize: 10,

	h1: 		{ fontSize: '2.6rem', fontWeight: 400, lineHeight: 1.4, },
	h2: 		{ fontSize: '2.0rem', fontWeight: 500, lineHeight: 1.4, },
	h3: 		{ fontSize: '1.6rem', fontWeight: 500, lineHeight: 1.4, },
	h4: 		{ fontSize: '2.6rem', fontWeight: 300, lineHeight: 1.4, },
	h5: 		{ fontSize: '2.0rem', fontWeight: 400, lineHeight: 1.4, },
	h6: 		{ fontSize: '1.6rem', fontWeight: 400, lineHeight: 1.4, },
	body1: 		{ fontSize: '1.4rem', fontWeight: 500, lineHeight: 1.4, },
	body2: 		{ fontSize: '1.4rem', fontWeight: 400, lineHeight: 1.4, },
	subtitle1: 	{ fontSize: '1.3rem', fontWeight: 500, lineHeight: 1.4, },
	subtitle2: 	{ fontSize: '1.3rem', fontWeight: 400, lineHeight: 1.4, },
	caption: 	{ fontSize: '1.2rem', fontWeight: 500, lineHeight: 1.4, },
	overline: 	{ fontSize: '1.4rem', fontWeight: 400, lineHeight: 1.4, },
	button: 	{ fontSize: '1.4rem', fontWeight: 600, lineHeight: 1.4, },

	fontWeightLight:	300,
	fontWeightRegular:	400,
	fontWeightMedium:	500,
	fontWeightBold:		600,
};

/*** Props ***/
const props = {
	MuiButton: {
		variant: 'contained',
	},
};

/*** Spacing: Used only for overrides and shouldn't be added to theme ***/
const spacing = (top:number, right?:number, bottom?:number, left?:number) => {
	const unit = 8;

	if(right !== undefined && bottom !== undefined && left !== undefined)
		return `${top * unit}px ${right * unit}px ${bottom * unit}px ${left * unit}px`;

	if(right !== undefined && bottom !== undefined)
		return `${top * unit}px ${right * unit}px ${bottom * unit}px`;

	if(right !== undefined)
		return `${top * unit}px ${right * unit}px`;

	return `${top * unit}px`;
}

/*** Overrides ***/
const overrides = {
	MuiButton: {
		root: 		{ padding: '7px  10px', fontSize: '1.3rem', minWidth: 0, lineHeight: 'initial', },
		sizeLarge: 	{ padding: '10px 16px', fontSize: '1.4rem', minWidth: 0, lineHeight: 'initial', '& .MuiButton-startIcon': { marginTop: -3, marginBottom: -3, } },
		sizeSmall: 	{ padding: '4px  8px',  fontSize: '1.2rem', minWidth: 0, lineHeight: 'initial', },
		contained:	{ boxShadow: 'none', color: palette.text.primary, backgroundColor: palette.grey[200] },
	},
	MuiIconButton: {
		root: { padding: spacing(1) },
	},
	MuiInputBase: {
		root: { ...typography.body2 },
	},
	MuiInputLabel: {
		outlined: { transform: 'translate(14px, 16px) scale(1)' },
	},
	MuiOutlinedInput: {
		root: { backgroundColor: palette.background.paper, '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: palette.primary.main }},
		input: { padding: spacing(1.75) },
		notchedOutline: { borderColor: palette.grey[200], borderRadius: 2,  },
		inputMarginDense: { paddingTop: 6, paddingBottom: 6, paddingLeft: 6, paddingRight: 6, },
	},
	MuiSelect: {
		outlined: { padding: 13 },
	},
	MuiTableRow: {
		root: { '&.Mui-selected': { backgroundColor: palette.primary.main +'11', '& > .MuiTableCell-body': { color: palette.primary.main }, '&:hover': { backgroundColor: palette.primary.main +'11' }}},
	},
	MuiTableCell: {
		root: { borderBottom: `1px solid ${palette.grey[50]}`, padding: spacing(2, 0.5), fontSize: '1.35rem', '&.empty': { padding: spacing(6, 1), fontStyle: 'italic', color: palette.text.hint } },
		head: { fontWeight: typography.fontWeightMedium, padding: spacing(0.5), },
		sizeSmall: { padding: spacing(0.75, 0.5) }
	},
	MuiDialogTitle: {
		root: { padding: spacing(0.5, 1), textAlign: 'center', backgroundColor: palette.grey[800], color: palette.theme.contrastText, 
			'& > .MuiTypography-h6': { fontSize: '1.3rem', lineHeight: 'initial', letterSpacing: 1, },
			'& .MuiIconButton-root': { position: 'absolute', top: 3, right: 3, padding: 0, borderRadius: 2, backgroundColor: palette.error.main, color: palette.error.contrastText, '& .MuiSvgIcon-root': { fontSize: 17, }, },
			'& .MuiIconButton-root:hover': { backgroundColor: palette.error.dark, },
		},
	},
	MuiDialogActions: {
		root: { padding: spacing(1.5) },
	},
	MuiAutocomplete: {
		inputRoot: { '&[class*="MuiOutlinedInput-root"]': { padding: spacing(0.5) } }
	},
};


const themeBuilder = (themeObject?: any, options?: ThemeOverride[]) => {

	const theme:any = {
		breakpoints: { values: breakpoints },
		palette,
		typography,
		overrides,
		props,
	};

    return createMuiTheme(theme);
};

export default themeBuilder;
