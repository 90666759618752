import React, { useReducer, useContext, createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability, AbilityBuilder } from '@casl/ability';

export type AbilityAction =
	| { type: 'SET_CLAIMS'; payload: any };

type State = [];

const getClaims = (claims: any[]) => {
	const ability = new Ability();
	const { can, cannot, rules } = new AbilityBuilder(Ability);
	claims.forEach((claim: any) => {
		if (claim.isCan) {
			can(claim.action, claim.subject);
		}
		else {
			cannot(claim.action, claim.subject, { isImpersonated: true });
		}
	});
	return ability.update(rules);
};

const initialState: any = getClaims([]);

type AbilityProviderProps = { children: React.ReactNode };

const AbilityStateContext = React.createContext<any>(getClaims([]));
const AbilityDispatchContext = React.createContext<Dispatch | undefined>(undefined);

type Dispatch = (action: AbilityAction) => void;

const Can = createContextualCan(AbilityStateContext.Consumer);

function abilityReducer(state: any, action: AbilityAction): any {
	switch (action.type) {
		case 'SET_CLAIMS':
			return getClaims(action.payload);
		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}

function AbilityProvider({ children }: AbilityProviderProps) {
	const [state, dispatch] = useReducer(abilityReducer, initialState);

	return (
		<AbilityStateContext.Provider value={state}>
			<AbilityDispatchContext.Provider value={dispatch}>
				{children}
			</AbilityDispatchContext.Provider>
		</AbilityStateContext.Provider>
	);
}

function useAbilityState(): any {
	const context = useContext(AbilityStateContext);
	if (context === undefined) {
		throw new Error(
			'useAbilityState must be used within a AbilityStateContext',
		);
	}
	return context;
}

function useAbilityDispatch(): Dispatch {
	const context = useContext(AbilityDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useAbilityDispatch must be used within a AbilityProvider',
		);
	}
	return context;
}

export { AbilityProvider, useAbilityState, useAbilityDispatch, Can, getClaims };
