import React, { useState, useEffect } from 'react';

// Src
import { useGlobalContext } from 'globalContext';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';
import Slide, { SlideProps } from '@material-ui/core/Slide';

export interface iSnackbarAlert {
	open: boolean;
	severity?: 'success' | 'info' | 'warning' | 'error';
	message?: string;
};

function TransitionUp(props:SlideProps) {
	return <Slide {...props} direction="up" />;
}

interface Props {
	open: boolean;
	severity?: 'success' | 'info' | 'warning' | 'error';
	message?: string;
	handleClose?: Function;
};

const SnackbarAlert: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { dispatch } = useGlobalContext();

	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(props.open);
	},[props.open]);

	const handleClose = () => {
		setOpen(false);

		dispatch({
			type: 'toast',
			payload: { open: false },
		});

		if(props.handleClose)
			props.handleClose();
	};

	return (
		<Snackbar open={open} autoHideDuration={6000} TransitionComponent={TransitionUp} onClose={handleClose}>
			<Alert variant="filled" severity={props.severity} data-color={props.severity} className={classes.alert} onClose={handleClose}>{props.message || ""}</Alert>
		</Snackbar>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		
	},
	alert: {
		padding: theme.spacing(0, 1.5, 0, 0),
		overflow: 'hidden',
		alignItems: 'center',
		fontSize: theme.typography.body1.fontSize,
		backgroundColor: '#323232',
		'& > .MuiAlert-icon': {
			padding: theme.spacing(1.5),
			fontSize: 28,
		},
		'&[data-color="success"] > .MuiAlert-icon': {
			backgroundColor: theme.palette.success.main,
		},
		'&[data-color="error"] > .MuiAlert-icon': {
			backgroundColor: theme.palette.error.main,
		},
		'&[data-color="warning"] > .MuiAlert-icon': {
			backgroundColor: theme.palette.warning.main,
		},
		'&[data-color="info"] > .MuiAlert-icon': {
			backgroundColor: theme.palette.info.main,
		},
	},
}));

export default SnackbarAlert;
