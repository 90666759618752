import React from 'react';
import { useField, ErrorMessage } from 'formik';

// Material-UI
import TextField from '@material-ui/core/TextField';

interface Props {
    name: string;
    label: string;
    [key: string]: any;
}

export const Input = ({ label, readOnly, ...props }: Props) => {
	const [field, meta] = useField(props);

	return (
		<TextField
			label={label}
			{...field}
			{...props}
			InputLabelProps={{ shrink: true }}
			inputProps={{ ...props.inputProps, readOnly: readOnly || null }}
			error={!!meta.touched && !!meta.error}
			helperText={<ErrorMessage name={field.name} />}
		/>
	);
};
