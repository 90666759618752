import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { ThemeInput } from '../../graphql/queries/themes';
import { ThemeProvider } from '@material-ui/styles';
import {
  AppBar,
  Button,
  Container,
  createMuiTheme,
  createStyles,
  Grid,
  Menu,
  MenuItem,
  Theme,
  ThemeOptions,
  Toolbar,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HelpSection } from './ThemePreviewComponents/HelpSection';
import { ColorSection } from './ThemePreviewComponents/ColorSection';
import { ButtonSection } from './ThemePreviewComponents/ButtonSection';
import { TypographySection } from './ThemePreviewComponents/TypographySection';

interface Props {
  formikProps: FormikProps<ThemeInput>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 350px)',
    },
    main: {
      marginTop: '5rem',
      marginLeft: '3rem',
      minHeight: '100vh',
    },
    grid: {
      margin: theme.spacing(4),
    },
  })
);

export const ThemePreview: React.FC<Props> = ({ formikProps }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const defaultTheme = useTheme();
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  /**
   * Further verfication for color hex string. 
   * Prevents crashing for empty strings and singular '#' values
   * @param value custom color value
   * @returns string
   */

  const verifyColor = (value: string):string => {
    if (value && value[0] === "#" && value.length > 1) return value;
    else return '';
  };

  useEffect(() => {
    const newTheme: ThemeOptions = {
      palette: {
        // Start: Added to prevent error.
        theme: {
          main:
          verifyColor(formikProps.values.colorPrimary) || defaultTheme.palette.theme.main,
        },
        // End
        primary: {
          main:
            verifyColor(formikProps.values.colorPrimary) ||
            defaultTheme.palette.primary.main,
        },
        secondary: {
          main:
            verifyColor(formikProps.values.colorSecondary) ||
            defaultTheme.palette.secondary.main,
        },
        text: {
          primary:
            verifyColor(formikProps.values.textPrimary) || defaultTheme.palette.text.primary,
          secondary:
            verifyColor(formikProps.values.textSecondary) ||
            defaultTheme.palette.secondary.main,
        },
        warning: {
          main: verifyColor(formikProps.values.warning) || defaultTheme.palette.warning.main,
        },
        info: {
          main: verifyColor(formikProps.values.info) || defaultTheme.palette.info.main,
        },
        error: {
          main: verifyColor(formikProps.values.error) || defaultTheme.palette.error.main,
        },
        success: {
          main: verifyColor(formikProps.values.success) || defaultTheme.palette.success.main,
        },
        background: {
          default:
            verifyColor(formikProps.values.background) ||
            defaultTheme.palette.background.default,
          paper:
            verifyColor(formikProps.values.panelBackground) ||
            defaultTheme.palette.background.paper,
        },
      },
      typography: {
        fontFamily:
          formikProps.values.fontFamily || defaultTheme.typography.fontFamily,
        h1: {
          fontSize:
            formikProps.values.h1 > 0
              ? formikProps.values.h1 + 'rem'
              : defaultTheme.typography.h1.fontSize,
          fontFamily:
            formikProps.values.h1Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        h2: {
          fontSize:
            formikProps.values.h2 > 0
              ? formikProps.values.h2 + 'rem'
              : defaultTheme.typography.h2.fontSize,
          fontFamily:
            formikProps.values.h2Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        h3: {
          fontSize:
            formikProps.values.h3 > 0
              ? formikProps.values.h3 + 'rem'
              : defaultTheme.typography.h3.fontSize,
          fontFamily:
            formikProps.values.h3Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        h4: {
          fontSize:
            formikProps.values.h4 > 0
              ? formikProps.values.h4 + 'rem'
              : defaultTheme.typography.h4.fontSize,
          fontFamily:
            formikProps.values.h4Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        h5: {
          fontSize:
            formikProps.values.h5 > 0
              ? formikProps.values.h5 + 'rem'
              : defaultTheme.typography.h5.fontSize,
          fontFamily:
            formikProps.values.h5Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        h6: {
          fontSize:
            formikProps.values.h6 > 0
              ? formikProps.values.h6 + 'rem'
              : defaultTheme.typography.h6.fontSize,
          fontFamily:
            formikProps.values.h6Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        body1: {
          fontSize:
            formikProps.values.body1 > 0
              ? formikProps.values.body1 + 'rem'
              : defaultTheme.typography.body1.fontSize,
          fontFamily:
            formikProps.values.body1Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        body2: {
          fontSize:
            formikProps.values.body2 > 0
              ? formikProps.values.body2 + 'rem'
              : defaultTheme.typography.body2.fontSize,
          fontFamily:
            formikProps.values.body2Font ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
        button: {
          fontSize:
            formikProps.values.button > 0
              ? formikProps.values.button + 'rem'
              : defaultTheme.typography.button.fontSize,
          fontFamily:
            formikProps.values.buttonFont ||
            formikProps.values.fontFamily ||
            'Roboto',
        },
      },
    };
    setTheme(createMuiTheme(newTheme));
  }, [formikProps]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <ThemeProvider theme={theme}>
      <div
        className={classes.root}
        style={{ background: theme.palette.background.default }}
      >
        <AppBar color={'primary'} position={'static'}>
          <Toolbar>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Example Menu
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Example Link</MenuItem>
              <MenuItem onClick={handleClose}>Example Link</MenuItem>
              <MenuItem onClick={handleClose}>Example Link</MenuItem>
            </Menu>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Example Menu
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleClose}
                style={{ fontFamily: formikProps.values.fontFamily }}
              >
                Example Link
              </MenuItem>
              <MenuItem onClick={handleClose}>Example Link</MenuItem>
              <MenuItem onClick={handleClose}>Example Link</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Container className={classes.main}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <HelpSection />
            </Grid>
            <Grid item xs={12}>
              <ColorSection />
            </Grid>
            <Grid item xs={12}>
              <ButtonSection />
            </Grid>
            <Grid item xs={12}>
              <TypographySection />
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
};
