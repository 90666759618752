export const LINKS = {
	HOME: '/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
	APP_SETTINGS: '/settings',
    THEMES: '/themes'
};

export const AUTH_TOKEN_NAME = 'tokenFromGQL';
export const REFRESH_TOKEN_NAME = 'refreshToken';
