import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Routes from './routes';
import { createBrowserHistory } from 'history';
import { ThemeOverride } from './interfaces';
import { setAuthInfo } from './utils/auth';
import { REFRESH_TOKEN } from './graphql/mutations/refreshToken';
import themeBuilder from './muiTheme';
// import themeBuilder from './buildMuiTheme';
import { tryRefreshToken } from './utils/auth';

// Material-UI
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

interface Props {
	isLoggedIn: Boolean;
	settings: {
		themeOverrides: ThemeOverride[];
		theme: any;
		siteName: string;
		themeName: string;
		resultsPerPage: string;
		currency: string;
	};
}

export const history = createBrowserHistory();

const App: React.FC<Props> = (props) => {
	const { isLoggedIn } = props;
	const [refreshToken] = useMutation(REFRESH_TOKEN, {
		onCompleted: ({ refreshToken }) => {
			setAuthInfo(refreshToken);
			// console.log('on completed function of refresh token ', refreshToken);
		},
		onError: (error) => {
			console.log('on error refresh token ', error);
		},
		update: (cache, result) => {
			// console.log('update function of refreshToken ', result);
		},
	});
	const [theme] = useState(
		themeBuilder(null, props.settings.themeOverrides || [])
	);

	useEffect(() => {
		// If user reloads the page and is logged in
		// we need to start watching for for the
		// token refresh
		// const authenticated = props.currentUser;
		//     props.startTokenRefreshWatch();
		// }
		if (isLoggedIn) {
			tryRefreshToken(refreshToken);
			// console.log('start refresh token process');
		}
	}, [isLoggedIn, refreshToken]);
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Routes/>
				<CssBaseline />
			</Router>
		</ThemeProvider>
	);
}

export default App;
