import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Helpers
import { LINKS } from 'helpers/constants';

// Icons
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingIcon from '@material-ui/icons/Settings';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// Components
import HeaderSearch from './HeaderSearch';
// import HeaderUser from './HeaderUser';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';

interface Props {}

const Header: React.FC<Props> = (props) => {
	const classes = useStyles();

	const [avatarEl, setAvatarEl] = useState<null | HTMLElement>(null);

	return (
		<AppBar position="static" elevation={0} className={classes.appBar}>
			<Toolbar className={classes.toolbar} disableGutters>
				<Hidden mdUp>
					{props.children}
				</Hidden>
				<Hidden xsDown>
					<ButtonBase className={classes.logoButton}><img alt="logo" src="/logo-white.svg" className={classes.logo} /></ButtonBase>
				</Hidden>
				<HeaderSearch/>
				<Hidden xsDown>
					{/* <Tooltip title="Notifications" aria-label="notifications">
						<IconButton aria-label="show 17 new notifications" color="inherit">
							<Badge badgeContent={17} color="error">
								<NotificationsIcon />
							</Badge>
						</IconButton>
					</Tooltip> */}
					<Tooltip title="Settings" aria-label="settings">
						<Link component={RouterLink} to={LINKS.APP_SETTINGS} underline="none" color="inherit">
							<IconButton aria-label="settings" color="inherit">
								<SettingIcon/>
							</IconButton>
						</Link>
					</Tooltip>
				</Hidden>
				<Hidden smUp>
					<IconButton aria-label="more" color="inherit" onClick={e => setAvatarEl(e.currentTarget)}>
						{/* <Badge badgeContent={17} color="error"> */}
							<MoreVertIcon/>
						{/* </Badge> */}
					</IconButton>

					<Menu id="simple-menu" anchorEl={avatarEl} open={Boolean(avatarEl)} elevation={2} onClose={e => setAvatarEl(null)} getContentAnchorEl={null} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
						{/* <MenuItem>
							<ListItemIcon className={classes.iconWrapper}>
								<Badge badgeContent={17} color="error">
									<NotificationsIcon fontSize="small" />
								</Badge>
							</ListItemIcon>
							<Typography variant="inherit">Notifications</Typography>
						</MenuItem> */}
						<Divider light className={classes.userMenuDivider}/>
						<Link component={RouterLink} to={LINKS.APP_SETTINGS} underline="none" color="inherit">
							<MenuItem>
								<ListItemIcon className={classes.iconWrapper}>
									<SettingIcon fontSize="small" />
								</ListItemIcon>
								<Typography variant="inherit">Settings</Typography>
							</MenuItem>
						</Link>
						
					</Menu>
				</Hidden>
				{/* <HeaderUser/> */}
			</Toolbar>
		</AppBar>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		backgroundColor: theme.palette.theme.main,
		color: theme.palette.theme.contrastText,
	},
	toolbar: {
		minHeight: 56,
		padding: theme.spacing(0, 1),
		[theme.breakpoints.down('xs')]: {
			padding: 0,
		},
	},
	logoButton: {
		borderRadius: theme.shape.borderRadius,
	},
	logo: {
		maxHeight: 40,
		padding: theme.spacing(0, 0.5),
	},
	userMenuDivider: {
		minWidth: 200,
	},
	iconWrapper: {
		minWidth: 30,
	}
}));

export default Header;
