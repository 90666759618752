import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/Help";


export const HelpSection:React.FC = () => (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
        >
            <Typography variant={'h2'}>Help <HelpIcon /></Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container direction={'column'}>
                <Grid item>

                </Grid>
                <Grid item>
                    <Typography variant={"h3"}>
                        Using the theme builder
                    </Typography>
                    <p>
                        The theme builder will help you style your website in the way you choose.
                        Using the menu on the right hand side of this page. You are able to customize your theme and preview the changes in real time
                    </p>
                </Grid>
                <Grid item>
                    <Typography variant={"h3"}>
                        Step 1: Selecting a base theme
                    </Typography>
                    <p>
                        Step one is where you are able to choose a base theme. This will allow you to preset the settings based on a theme either we have provided.
                        Or a theme that you have already built in the past, this is recommended as it will give you a baseline for the rest of your customization
                    </p>
                </Grid>
                <Grid item>
                    <Typography variant={"h3"}>
                        Step 2: Choose your theme colors
                    </Typography>
                    <p>
                        Once you have selected a base theme, you can then tweak the colors using hex codes or the color picker that has been provided.
                    </p>
                </Grid>
                <Grid item>
                    <Typography variant={"h3"}>
                        Step 3: Choose your fonts
                    </Typography>
                    <p>
                        The first option is the default font, this is the font that will be applied to the entire site. You can then choose the sizes of all your header and body fonts
                        If you want to override the font for any component, hit the 'Override font' switch and you can specify a font that will only be applied to the font type you are editing
                    </p>
                </Grid>
                <Grid item>
                    <Typography variant={"h3"}>
                        Step 4: Media breakpoints (advanced)
                    </Typography>
                    <p>
                        Media breakpoints is when the site will subtly switch the layout of the UI based on screen size. You can control the pixel width at which this change takes place
                        However this is an advanced option and unless you understand what you are doing, it is recommended to leave them at default.
                    </p>
                </Grid>
                <Grid item>
                    <Typography variant={"h3"}>
                        Step 5: Save your theme
                    </Typography>
                    <p>
                        Once you are happy with your settings, save the theme. You can choose a name to easily locate the theme in the themes list.
                        If you started with a default theme, you will be forced to only save as a new theme. If its a theme you have created, you will be able to update the existing one.
                    </p>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
)