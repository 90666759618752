import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from 'helpers/constants';
import { LoginSuccessEvent, DecodedToken } from 'interfaces';
import { decodeToken } from 'services/auth.service';

const tokenStorage: Storage = localStorage;
interface clearTokenOption {
    redirectToRoot: boolean;
}

const setAuthInfo = (loginData: LoginSuccessEvent) => {
    tokenStorage.setItem(AUTH_TOKEN_NAME, loginData.accessToken.token);
    tokenStorage.setItem(REFRESH_TOKEN_NAME, loginData.refreshToken);
};
const getAuthToken = () => {
    const token = tokenStorage.getItem(AUTH_TOKEN_NAME);
    return token;
};

export const getUserFromToken = (token: string | null): DecodedToken | null => {
    if (!token) {
        return null;
    }
    let user = decodeToken(token);
    return user;
};
const getRefreshToken = () => {
    const refreshToken = tokenStorage.getItem(REFRESH_TOKEN_NAME);
    return refreshToken;
};
const clearToken = (options: clearTokenOption = { redirectToRoot: true }) => {
    tokenStorage.removeItem(AUTH_TOKEN_NAME);
    tokenStorage.removeItem(REFRESH_TOKEN_NAME);

    if (options.redirectToRoot && window.location.pathname !== '/') {
        if (window !== undefined) {
            window.location.href = '/';
        }
    }
};

const tryRefreshToken = (fn: Function) => {
    let token = getAuthToken();
    let refreshToken = getRefreshToken();

    if (token && refreshToken) {
        fn({
            variables: {
                accessToken: token,
                refreshToken,
            },
        });
    } else {
        console.log('token issue log out');
    }
};
export { getAuthToken, clearToken, tryRefreshToken, setAuthInfo };
