import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import DefaultLayout from 'pages/_layouts/default';
import AuthLayout from 'pages/_layouts/auth';
// import InnerLayout from 'pages/_layouts/inner';
import { IS_LOGGED_IN } from 'graphql/queries/isLoggedIn';

const layouts = new Map();
layouts.set('default', DefaultLayout);
layouts.set('auth', AuthLayout);
// layouts.set('inner', InnerLayout);

interface Props {
    component: any;
    isPrivate?: boolean;
    path?: string;
    exact?: boolean;
    layout?: string;
    location?: any;
    getPropsFromPage?: (title: string) => void;
}

const RouteWrapper: React.FC<Props> = ({
    component: Component,
    isPrivate,
    layout,
    location,
    getPropsFromPage,
    ...rest
}) => {
    const { data } = useQuery(IS_LOGGED_IN);
    // const authenticated = currentUser && currentUser.exp > Date.now() / 1000;
    const authenticated = data?.isLoggedIn || false;

    /** Redirect user to Login page if he tries to access a private route
     * without authentication
     */

    if (isPrivate && !authenticated) {
        return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
    }

    let Layout = AuthLayout;
    if (layout && layouts.has(layout)) {
        Layout = layouts.get(layout);
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} getPropsFromPage={getPropsFromPage} />
                </Layout>
            )}
        />
    );
};
// export default connect(mapState)(RouteWrapper);
export default RouteWrapper;
